.App {
    padding: 0;
    background: black;
    height: 100vh;
    width: 100%;
    max-width: 100%;
}


#video-player {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.opacity {
    opacity: 0.2;
}

.hidden {
    display: none;
}

#start_button {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border: none;
}

#content {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

#header {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2em;
    z-index: 999;
    width: 100%;
    min-width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 35%, rgba(0,0,0,0) 100%);
}

#footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em;
    z-index: 999;
    width: 100%;
    min-width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 35%, rgba(0,0,0,0) 100%);
}

#no-time {
    padding-top: 20em;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}